import {
  CZECH_COUNTRY_ID, DHL_DELIVERY_SERVICE,
  HEARTYSAN_USER_TYPES, LATVIA_COUNTRY_ID, POLAND_COUNTRY_ID,
  RUSSIA_COUNTRY_ID,
  SYSTEM_ROLES,
  TNT_DELIVERY_SERVICE, UKRAINIAN_COUNTRY_ID, UNITED_KINGDOM_ID
} from "../../staticData/staticVariables";

export const userDataMixin = {


  computed: {

    getCurrentUserProfile() {
      return this.$store.getters.getUserProfile
    },

    getCurrentUserSetting() {
      return this.$store.getters.getUserProfile?.user_setting
    },

    /**
     * If user has Belarusian country group
     * @returns {boolean|*}
     */
    isBelarus() {
      let user = this.$store.getters.getUserProfile,
        userCountryGroups = user.user_personal_contact?.country_group

      if (userCountryGroups)
        return userCountryGroups['fromBelarusGroup']

      return false
    },

    /**
     * If user has Latvian country group
     * @returns {boolean|*}
     */
    isLatvian() {
      let user = this.$store.getters.getUserProfile,
        userCountryGroups = user.user_personal_contact?.country_group

      if (userCountryGroups)
        return userCountryGroups['fromBalticPlusGroup']

      return false
    },

    /**
     * If user has Poland country group
     * @returns {boolean|*}
     */
    isPoland() {
      let user = this.$store.getters.getUserProfile,
        userCountryGroups = user.user_personal_contact?.country_group

      if (userCountryGroups)
        return userCountryGroups['fromPlGroup']

      return false
    },

    /**
     * If user has ukrainian country group
     * @returns {boolean|*}
     */
    isUkrainian() {
      let user = this.$store.getters.getUserProfile,
        userCountryGroups = user.user_personal_contact?.country_group

      if (userCountryGroups)
        return userCountryGroups['fromUkraineGroup']

      return false
    },

    /**
     * If user has baltic plus country group
     * @returns {boolean|*}
     */
    isBaltic() {
      let user = this.$store.getters.getUserProfile,
        userCountryGroups = user.user_personal_contact?.country_group

      if (userCountryGroups)
        return userCountryGroups['fromBalticPlusGroup']

      return false
    },

    isSNGGroup() {
      let user = this.$store.getters.getUserProfile,
        userCountryGroups = user.user_personal_contact?.country_group

      if (userCountryGroups)
        return userCountryGroups['fromSNGGroup']

      return false
    },

    isUSAGroup() {
      let user = this.$store.getters.getUserProfile,
        userCountryGroups = user.user_personal_contact?.country_group

      if (userCountryGroups)
        return userCountryGroups['fromUSAGroup']

      return false
    },


    /**
     * If user has tamozenniy soyz country group
     * @returns {boolean|*}
     */
    isEAEUGroup() {
      let user = this.$store.getters.getUserProfile,
        userCountryGroups = user.user_personal_contact?.country_group

      if (userCountryGroups)
        return userCountryGroups['fromEaeuGroup']

      return false
    },

    isUseAlternativeContact() {
      let user = this.$store.getters.getUserProfile
      if(user?.use_alternative_address === 0) return false
      return true
    },

    getCurrentUserAlternativeContact() {
      let user = this.$store.getters.getUserProfile
      return user?.user_alternative_contact
    },

    isUserAlternativeContactPoland() {
      let user = this.$store.getters.getUserProfile,
        userCountryGroups = user.user_alternative_contact?.country_group
      if(user?.use_alternative_address === 0) return false

      if (userCountryGroups)
        return userCountryGroups['fromPlGroup']

      return false
    },

    isUserAlternativeContactUkraine() {
      let user = this.$store.getters.getUserProfile,
        userCountryGroups = user.user_alternative_contact?.country_group
      if(user?.use_alternative_address === 0) return false

      if (userCountryGroups)
        return userCountryGroups['fromUkraineGroup']

      return false
    },

    isUserUkraineOrAlternativeContactUkraine() {
      let user = this.$store.getters.getUserProfile,
        userCountryGroups = user.user_alternative_contact?.country_group
      if(user?.use_alternative_address === 0) return this.isUkrainian

      if (userCountryGroups)
        return userCountryGroups['fromUkraineGroup']

      return false
    },


    /**
     * If user from russia
     * @returns {boolean|*}
     */
    isRussiaCountry() {
      let user = this.$store.getters.getUserProfile

      if (user.user_personal_contact?.country_id)
        return user.user_personal_contact?.country_id === RUSSIA_COUNTRY_ID

      return false
    },

    /**
     * If user from Czech
     * @returns {boolean|*}
     */
    isCzechCountry() {
      let user = this.$store.getters.getUserProfile

      if (user.user_personal_contact?.country_id)
        return user.user_personal_contact?.country_id === CZECH_COUNTRY_ID

      return false
    },

    /**
     * If user from Latvia
     * @returns {boolean|*}
     */
    isLatviaCountry() {
      let user = this.$store.getters.getUserProfile

      if (user.user_personal_contact?.country_id)
        return user.user_personal_contact?.country_id === LATVIA_COUNTRY_ID

      return false
    },

    /**
     * If user from United Kingdom
     * @returns {boolean|*}
     */
    isUnitedKingdomCountry() {
      let user = this.$store.getters.getUserProfile,
        userCountryId = user.user_personal_contact?.country?.id

      if (userCountryId)
        return this.isUnitedKingdomCountryByUserData(userCountryId)

      return false
    },

    /**
     * Get user proform lang by his country group
     * @returns {{lang: string}}
     */
    proformUserConfig() {
      let user = this.$store.getters.getUserProfile,
        userCountryGroup = user.user_personal_contact?.country_group

      if (!userCountryGroup) return {lang: 'ua'}

      if (this.isBelarus) return {lang: 'en'}

      if (this.isUnitedKingdomCountry) return {lang: 'en'}

      if (this.isUkrainian) return {lang: 'ua'}

      if (this.isSNGGroup) return {lang: 'ru'}

      if (this.isUSAGroup) return {lang: 'en'}

      if (this.isLatvian) return {lang: 'en'}

      return {lang: 'en'}
    },

    /**
     * Get default user role
     * @returns {boolean}
     */
    isCurrentUserRoleUser() {
      return this.$store.getters.getIsAdminRights === 'user'
    },

    /**
     * Get Admin role
     * @returns {boolean}
     */
    isAdmin() {
      return this.$store.getters.getIsAdminRights === 'admin'
    },

    /**
     * Check if subUser
     * @returns {boolean}
     */
    // isSubUser() {
    //   return this.$store.getters.GET_COMMON_AUTHORIZED.user?.company_owner?.id !== undefined
    // },

    /**
     * Get user
     * @returns {any}
     */
    getCurrentUser() {
      return this.$store.getters.GET_COMMON_AUTHORIZED?.user
    },

    /**
     * Get user
     * @returns {any}
     */
    getCurrentImpersonateUser() {
      return this.$store.getters.GET_COMMON_AUTHORIZED?.user?.impersonated_user || null
    },

    /**
     * Get COMMON_AUTHORIZED config by current user
     * @returns {any}
     */
    loadUserAuthorizedData() {
      if (this.$store.getters.GET_COMMON_AUTHORIZED?.user) {
        return this.$store.getters.GET_COMMON_AUTHORIZED
      }
      return false
    },

    /**
     * Get load Data Current User Rights
     * @returns {any}
     */
    loadUserRoleData() {
      return this.$store.getters.getCurrentUserRights
    },

    /**
     * Get load data User Role And Authorized config
     * @returns {boolean}
     */
    loadUserRoleAndAuthorizedData() {
      if (this.$store.getters.getCurrentUserRights?.role &&
        this.$store.getters.GET_COMMON_AUTHORIZED?.user) {
        return true
      }
      return false
    },


    /**
     * Get current user country_group
     * @returns {object}
     */
    getUserCountryGroup() {
      let user = this.$store.getters.getUserProfile
      return user.user_personal_contact?.country_group
    },

    /**
     * Check is Developer
     * @returns {boolean}
     */
    isDeveloper() {
      return this.$store.getters.getCurrentUserRights?.role?.id === SYSTEM_ROLES.SYSTEM_ROLE_DEVELOPER.id
    },

    /**
     * Check is SupperAdmin
     * @returns {boolean}
     */
    isSupperAdmin() {
      return this.$store.getters.getCurrentUserRights?.role?.id === SYSTEM_ROLES.SYSTEM_ROLE_SUPER_ADMIN.id
    },

    /**
     * Check is SubAdmin
     * @returns {boolean}
     */
    isSubAdmin() {
      return this.$store.getters.getCurrentUserRights?.role?.id === SYSTEM_ROLES.SYSTEM_ROLE_ADMIN.id
    },

    /**
     * Check is SubUser
     * @returns {boolean}
     */
    isSubUser() {
      return this.$store.getters.getCurrentUserRights?.role?.id === SYSTEM_ROLES.SYSTEM_ROLE_SUB_USER.id
    },

    /**
     * Check is Current User heartysan manufacturer
     * @returns {boolean}
     */
    isUserHeartyManufacturer() {
      return this.getCurrentUser?.hearty_user_type?.id === HEARTYSAN_USER_TYPES.HEARTYSAN_MANUFACTURER.id
    },

    /**
     * Check is Current User heartysan seller
     * @returns {boolean}
     */
    isUserHeartySeller() {
      return this.getCurrentUser?.hearty_user_type?.id === HEARTYSAN_USER_TYPES.HEARTYSAN_SELLER.id
    },

    /**
     * Check is Current User has easy orders
     * @returns {boolean}
     */
    isUserHasEasyOrder() {
      return this.getCurrentUser?.easy_cabinet || false
    },

  },

  methods: {
    /**
     * Check if deliveryServiceId DHL and user from United Kingdom
     * @returns {boolean|*}
     */
    checkDHLExpressUserFromGB(deliveryServiceId) {
      if (this.isAdmin || deliveryServiceId !== DHL_DELIVERY_SERVICE.id) return true

      let userContacts = this.getUserContactDataFromUser(this.getCurrentUser)
      if(this.getUserAlternativeContact(this.getCurrentUser)) {
        userContacts = this.getUserAlternativeContact(this.getCurrentUser)
      }

      return !this.isUnitedKingdomCountryByUserData(userContacts?.country_id)
    },

    checkTNTAllCountriesOrUSAGroup(country) {
      if (this.isAdmin)
        return true

      if(!country.country_group) return true

      return !country.country_group.fromUSAGroup || this.getUserTntAllCountriesSetting
    },

    /**
     * Check if deliveryServiceId TNT and user has Ukrainian country group
     * @returns {boolean|*}
     */
    checkUkrainianUserInTNT(deliveryServiceId) {
      if (TNT_DELIVERY_SERVICE.id !== deliveryServiceId || this.isAdmin)
        return true

      return TNT_DELIVERY_SERVICE.id === deliveryServiceId && this.isUkrainian
    },

    /**
     * If user has Belarusian country group
     * @returns {boolean|*}
     */
    isBelarusByUserData(country_group) {
      // let currUser = this.getUserContactDataFromUser(user)
      //
      // let userCountryGroups = currUser?.country_group

      if (country_group)
        return country_group['fromBelarusGroup']

      return false
    },

    /**
     * If user has ukrainian country group
     * @returns {boolean|*}
     */
    isUkrainianByUserData(country_group) {
      // let currUser = this.getUserContactDataFromUser(user)

      // let userCountryGroups = currUser?.country_group


      if (country_group)
        return country_group['fromUkraineGroup']

      return false
    },

    isPolandByUserData(country_group) {

      if (country_group)
        return country_group['fromPlGroup']

      return false
    },

    isSNGGroupByUserData(country_group) {
      // let currUser = this.getUserContactDataFromUser(user)
      //
      // let userCountryGroups = currUser?.country_group


      if (country_group)
        return country_group['fromSNGGroup']

      return false
    },

    isUSAGroupByUserData(user) {
      let currUser = this.getUserContactDataFromUser(user)

      let userCountryGroups = currUser?.country_group


      if (userCountryGroups)
        return userCountryGroups['fromUSAGroup']

      return false
    },

    isBalticByUserData(country_group) {

      if (country_group)
        return country_group['fromBalticPlusGroup']

      return false
    },

    isRussiaCountryByUserData(user) {
      if (user.user_personal_contact?.country_id)
        return user.user_personal_contact?.country_id === RUSSIA_COUNTRY_ID

      return false
    },

    isUkraineCountryByUserData(country_id) {
      return country_id === UKRAINIAN_COUNTRY_ID
    },

    isUnitedKingdomCountryByUserData(country_id) {
      return country_id === UNITED_KINGDOM_ID
    },

    isPolandCountryByUserData(country_id) {
      return country_id === POLAND_COUNTRY_ID
    },

    isLatviaCountryByUserData(country_id) {
      return country_id === LATVIA_COUNTRY_ID
    },

    getUserContactDataFromUser(user) {
      if (user?.user_personal_contact) {
        return user?.user_personal_contact
      } else {
        return this._.first(user?.contacts)
      }
    },

    getUserAlternativeContact(user) {
      if(user?.use_alternative_address === 0) return false
      if (user?.user_alternative_contact) {
        return user?.user_alternative_contact
      }
      return false
    },

    getUserContactWithCheckAlternative(user) {
      if(user?.use_alternative_address !== 0) {
        return this.getUserAlternativeContact(user)
      }

      if(user?.use_alternative_address === 0) {
        return this.getUserContactDataFromUser(user)
      }

      return false
    },

    getUserPayoneerBalance(user) {
      return this._.find(user?.user_balances, {payment_system: 'payoneer'})?.balance || null
    },


  }
}
