import {API} from "../../axios/axiosMainUrl";

const prefix = '/easy-order'

export const easyOrders = {
  state: {
    easyOrders: [],
    easyOrdersItem: {},
    easyOrdersCommonList: [],
    easyOrdersBtn: false,
    easyOrdersLoading: true,
    nextEasyOrdersPage: false,
    easyOrdersForPage: 25,
    easyOrdersFilter: '',
  },

  mutations: {
    setEasyOrdersFilter: (state, payload) => {
      state.easyOrdersFilter = payload
    },

    setEasyOrders: (state, payload) => {
      if(state.nextEasyOrdersPage) {
        state.easyOrders = state.easyOrders.concat(payload.easyOrders)
      } else {
        state.easyOrders = payload.easyOrders
      }
    },

    setEasyOrdersCommonList: (state, payload) => {
      state.easyOrdersCommonList = payload.easyOrdersCommonList
    },

    setNextEasyOrdersPage: (state, payload) => {
      state.nextEasyOrdersPage = payload
    },

    setEasyOrdersItem: (state, payload) => {
      state.easyOrdersItem = payload.easyOrdersItem
    },

    setEasyOrdersLoadingStart: (state) => {
      state.easyOrdersLoading = true
    },

    setEasyOrdersLoadingEnd: (state) => {
      state.easyOrdersLoading = false
    },

    changeEasyOrdersBtn: (state) => {
      state.easyOrdersBtn = true
    },
    successEasyOrdersBtn: (state) => {
      state.easyOrdersBtn = false
    },
  },

  getters: {
    getEasyOrdersFilter: state => {
      return state.easyOrdersFilter
    },

    getEasyOrdersForPage: state => {
      return state.easyOrdersForPage
    },

    getEasyOrders: state => {
      return state.easyOrders
    },

    getEasyOrdersLoading: state => {
      return state.easyOrdersLoading
    },


    getNextEasyOrdersPage: state => {
      return state.nextEasyOrdersPage
    },

    getEasyOrdersItem: state => {
      return state.easyOrdersItem
    },

    getEasyOrdersCommonList: state => {
      return state.easyOrdersCommonList
    },

    getEasyOrdersBtn: state => {
      return state.easyOrdersBtn
    },
  },

  actions: {

    async fetchEasyOrders({commit, getters}, filter = '') {
      if(!getters.getNextEasyOrdersPage)
        commit('setEasyOrdersLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setEasyOrders',{easyOrders: response.data.data.data})
          // commit('setEasyOrdersCommonList',{easyOrdersCommonList: response.data.data})
          commit('setEasyOrdersCommonList',{easyOrdersCommonList: {...response.data.data.meta, ...{
                next_page_url: response.data.data.links.next,
                first_page_url: response.data.data.links.first,
                last_page_url: response.data.data.links.last,
                prev_page_url: response.data.data.links.prev,
                balance: response.data.data.balance,
              }}})
          commit('setEasyOrdersLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setEasyOrdersLoadingEnd');
        return e;
      }
    },

    async getEasyOrders({commit}, id) {
      commit('setEasyOrdersLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setEasyOrdersItem', {easyOrdersItem: response.data.data})
          commit('setEasyOrdersLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setEasyOrdersLoadingEnd');
        return e;
      }
    },

    async getEasyOrdersExpress({commit}, id) {
      commit('setEasyOrdersLoadingStart');
      try {
        return await API.get(`${prefix}/express/${id}`).then((response) =>{
          commit('setEasyOrdersItem', {easyOrdersItem: response.data.data})
          commit('setEasyOrdersLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setEasyOrdersLoadingEnd');
        return e;
      }
    },

    async getEasyOrdersFBMConsolidation({commit}, id) {
      commit('setEasyOrdersLoadingStart');
      try {
        return await API.get(`${prefix}/econom/${id}`).then((response) =>{
          commit('setEasyOrdersItem', {easyOrdersItem: response.data.data})
          commit('setEasyOrdersLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setEasyOrdersLoadingEnd');
        return e;
      }
    },

    async getEasyOrdersFBM({commit}, id) {
      commit('setEasyOrdersLoadingStart');
      try {
        return await API.get(`${prefix}/warehouse-fbm/${id}`).then((response) =>{
          commit('setEasyOrdersItem', {easyOrdersItem: response.data.data})
          commit('setEasyOrdersLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setEasyOrdersLoadingEnd');
        return e;
      }
    },

    async createEasyOrders({commit}, data) {
      commit('changeEasyOrdersBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successEasyOrdersBtn');
          return response;
        });
      } catch (e) {
        commit('successEasyOrdersBtn');
        return e;
      }
    },

    async createEasyOrdersFBM({commit}, data) {
      commit('changeEasyOrdersBtn');
      try {
        return await API.post(`${prefix}/warehouse-fbm`, data).then((response) =>{
          commit('successEasyOrdersBtn');
          return response;
        });
      } catch (e) {
        commit('successEasyOrdersBtn');
        return e;
      }
    },

    async createEasyOrdersFBMConsolidation({commit}, data) {
      commit('changeEasyOrdersBtn');
      try {
        return await API.post(`${prefix}/econom`, data).then((response) =>{
          commit('successEasyOrdersBtn');
          return response;
        });
      } catch (e) {
        commit('successEasyOrdersBtn');
        return e;
      }
    },

    async createEasyOrdersExpress({commit}, data) {
      commit('changeEasyOrdersBtn');
      try {
        return await API.post(`${prefix}/express`, data).then((response) =>{
          commit('successEasyOrdersBtn');
          return response;
        });
      } catch (e) {
        commit('successEasyOrdersBtn');
        return e;
      }
    },

    async updateEasyOrders({commit}, {id, data}) {
      commit('changeEasyOrdersBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successEasyOrdersBtn');
          return response;
        });
      } catch (e) {
        commit('successEasyOrdersBtn');
        return e;
      }
    },

    async updateEasyOrdersFBM({commit}, {id, data}) {
      commit('changeEasyOrdersBtn');
      try {
        return await API.put(`${prefix}/warehouse-fbm/${id}`, data).then((response) =>{
          commit('successEasyOrdersBtn');
          return response;
        });
      } catch (e) {
        commit('successEasyOrdersBtn');
        return e;
      }
    },

    async updateEasyOrdersFBMConsolidation({commit}, {id, data}) {
      commit('changeEasyOrdersBtn');
      try {
        return await API.put(`${prefix}/econom/${id}`, data).then((response) =>{
          commit('successEasyOrdersBtn');
          return response;
        });
      } catch (e) {
        commit('successEasyOrdersBtn');
        return e;
      }
    },

    async updateEasyOrdersExpress({commit}, {id, data}) {
      commit('changeEasyOrdersBtn');
      try {
        return await API.put(`${prefix}/express/${id}`, data).then((response) =>{
          commit('successEasyOrdersBtn');
          return response;
        });
      } catch (e) {
        commit('successEasyOrdersBtn');
        return e;
      }
    },

    async deleteEasyOrdersFBM({commit}, id) {
      try {
        return await API.delete(`${prefix}/warehouse-fbm/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successEasyOrdersBtn');
        return e;
      }
    },

    async deleteEasyOrdersFBMConsolidation({commit}, id) {
      try {
        return await API.delete(`${prefix}/econom/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successEasyOrdersBtn');
        return e;
      }
    },

    async deleteEasyOrdersExpress({commit}, id) {
      try {
        return await API.delete(`${prefix}/express/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successEasyOrdersBtn');
        return e;
      }
    },

    async getEasyEconomShippingCompany({commit}, data) {
      commit('changeEasyOrdersBtn');
      commit('setOrdersFBMCompanyLoading');
      try {
        return await API.post(`${prefix}/econom/shipping-company`, data).then((response) =>{
          commit('successEasyOrdersBtn');
          commit('setOrdersFBMCompanyLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('successEasyOrdersBtn');
        commit('setOrdersFBMCompanyLoadingEnd');
        return e;
      }
    },

    async getEasyEconomRate({commit}, data) {
      commit('changeEasyOrdersBtn');
      commit('setOrdersFBMLoadingStart');
      commit('changeOrdersFBABtn');
      try {
        return await API.post(`${prefix}/econom/rate`, data).then((response) =>{
          commit('successEasyOrdersBtn');
          commit('setOrdersFBMLoadingEnd');
          commit('successOrdersFBABtn');
          return response;
        });
      } catch (e) {
        commit('successEasyOrdersBtn');
        commit('setOrdersFBMLoadingEnd');
        commit('successOrdersFBABtn');
        return e;
      }
    },

  }
}
