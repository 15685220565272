<template>
<!--  <div class="default-select"-->
<!--       :class="{'default-select&#45;&#45;active' : selected && selected.length > 0}"-->
<!--  >  -->
    <div class="default-select"
       :class="{
          'default-select--active' : vSelectModel,
          'disabled-active' : disabled === true && vSelectModel,
          'disabled' : disabled === true
        }"
  >

    <v-select
        :options="options"
        @input="onChange"
        v-model="vSelectModel"
        :label="optionsLabel"
        append-to-body
        :calculate-position="withPopper"
        :disabled="disabled"
        :filter-by="myFilter"
        :clearable="clearable"
        :searchable="searchable"
        :selectable="option => !option.hasOwnProperty('disabled')"
    >
      <template #header>
        <div class="v-select__label">{{ label }}</div>
      </template>


      <template slot="option" slot-scope="option" v-if="otherValue !== ''">
        <!-- *********************** SAVED PROFORMS *********************** -->
        <div v-if="otherValue === 'savedProforms'">
          <span v-for="(item, index) in option.values" :key="index">
            {{item.translationStorage[proformUserConfig.lang].name}}
          </span>
          {{ option.handmade === '1' ? $t('product_Handmade.localization_value.value') : '' }} &nbsp;
          {{ option.item_price }} * {{option.item_quantity}}
        </div>

        <!-- *********************** PROFORM HS CODE *********************** -->
        <div class="d-flex flex-column" v-if="otherValue === 'proformHSCode'">
          {{ option.proform_value.translationStorage.ua.name }}
        </div>

        <!-- *********************** SEND FROM *********************** -->
        <div class="d-flex" v-if="otherValue === 'sendFrom'">
          {{ option.first_name }}
          {{ option.last_name }},
          {{ option.company_name }}
        </div>

        <!-- *********************** PROFORM DATA *********************** -->
        <div class="d-flex flex-column" v-if="otherValue === 'proformData'">
          {{ option.translationStorage[proformUserConfig.lang].name }}
        </div>

        <!-- *********************** PROFORM DATA PRODUCT *********************** -->
        <div class="d-flex flex-column" v-if="otherValue === 'proformDataProduct' && option && option.value">
          {{ option.value.translationStorage[proformUserConfig.lang].name }}
        </div>

        <!-- *********************** NOVA POSHTA DEPARTMENT *********************** -->
        <div class="d-flex flex-column" v-if="otherValue === 'novaPoshtaDepartment'">
          {{ option.translationStorage.ua.name }}
        </div>

        <!-- *********************** PACKAGING SELECT *********************** -->
        <div class="d-flex flex-column" v-if="otherValue === 'packagingSelect'">
          <div>
            {{ option.package_name }}
            <span>
              ({{ option.height }}*{{ option['length'] }}*{{ option.weight }}*{{ option.width }})
            </span>
          </div>
        </div>

        <!-- *********************** SELECT ICONS *********************** -->
        <div class="d-flex" v-if="otherValue === 'selectIcons'">
          <span class="default-select__option-ico"
                v-if="option[optionIcoVariable] || option.src"
          >
            <img :src="optionIcoVariable ? option[optionIcoVariable] : option.src" alt="ico" :height="heightIco"/>
          </span>
          {{ optionIcoName ? option[optionIcoName] : option.title }}
        </div>

        <!-- *********************** GROUPS *********************** -->
        <div class="d-flex" v-if="otherValue === 'groups'">
          <div v-if="option.group" class="group">
            {{ option.group }}
          </div>
          {{ option.name }}
        </div>

        <!-- *********************** ACCOUNTS *********************** -->
        <div class="d-flex" v-if="otherValue === 'accounts'">
          <img class="mr-2" v-if="option.bank.logo_base64" :src="option.bank.logo_base64" alt="ico" height="18">
          <img v-else width="15" class="mr-2 mt--1" src="/img/dashboard-group/balance-icon.svg" alt="img">


          <span class="word-break">
            <template v-if="isIndividualBank(option)">
              <template v-if="
                option.bank_id !== ACCOUNT_BANK_LIST.ACCOUNT_BANK_WISE.id &&
                option.bank_id !== ACCOUNT_BANK_LIST.ACCOUNT_BANK_PAYONEER.id &&
                option.bank_id !== ACCOUNT_BANK_LIST.ACCOUNT_BANK_SWIFT.id &&
                option.bank_id !== ACCOUNT_BANK_LIST.ACCOUNT_BANK_KIT_GROUP.id &&
                option.bank_id !== ACCOUNT_BANK_LIST.ACCOUNT_BANK_RAMP.id &&
                option.bank_id !== ACCOUNT_BANK_LIST.ACCOUNT_BANK_CRYPTO.id">
                {{option.bank.currentTranslate.name}}<template v-if="option.card_number">,
                {{option.card_number}}</template><template v-if="option.first_name">,
                {{option.first_name}}</template><template v-if="option.last_name">
                 {{option.last_name}}</template><template v-if="option.email">,
                {{option.email}}</template>
              </template>
              <template v-else-if="
                option.bank_id === ACCOUNT_BANK_LIST.ACCOUNT_BANK_RAMP.id">
                {{option.bank.currentTranslate.name}}<template v-if="option.card_number">,
                {{option.card_number}}</template><template v-if="option.middle_name">,
                {{option.middle_name}}</template>
              </template>
              <template v-else>
                {{option.bank.currentTranslate.name}}<template v-if="option.full_name">,
                {{option.full_name}}</template>
                <template v-if="option.phone && option.bank_id === ACCOUNT_BANK_LIST.ACCOUNT_BANK_KIT_GROUP.id">,
                  {{option.phone}}, {{option.city}}</template>
                <template v-if="option.iban && option.bank_id === ACCOUNT_BANK_LIST.ACCOUNT_BANK_SWIFT.id">,
                  {{option.iban}}</template>
                <template v-if="option.crypto_name && option.bank_id === ACCOUNT_BANK_LIST.ACCOUNT_BANK_CRYPTO.id">,
                  {{option.crypto_name}}</template>
                <template v-if="option.crypto_wallet && option.bank_id === ACCOUNT_BANK_LIST.ACCOUNT_BANK_CRYPTO.id">,
                  {{option.crypto_wallet}}</template>
              </template>
            </template>

            <template v-if="isBusinessBank(option)">
              {{option['full_name']}},
              {{option['iban']}}
            </template>
          </span>
        </div>

        <!-- *********************** CURRENT TRANSLATION *********************** -->
        <div class="d-flex" v-if="otherValue === 'currentTranslation'">
          {{ option.currentTranslate.name }}
        </div>

        <!-- *********************** CALCULATOR CUSTOMIZATION *********************** -->
        <div class="d-flex" v-if="otherValue === 'calculatorCustomization'">
          {{ option.name }}, ${{ option.price }}
        </div>

        <!-- *********************** TRANSLATION *********************** -->
        <div class="d-flex" v-if="otherValue === 'translation'">
          {{ $t(`${option.translation}.localization_value.value`)}}
        </div>

        <!-- *********************** CONSOLIDATION UNION *********************** -->
        <div class="d-flex" v-if="otherValue === 'consolidationUnion'">
          {{option.delivery_service.name}}:
          {{option.tracking_number}}
        </div>

        <!-- *********************** ADMIN *********************** -->
        <div v-if="otherValue === 'adminItem'">
          {{option.user_personal_contact.user_full_name}} <br>
          <b>{{option.email}}</b>
        </div>

        <!-- *********************** DEFAULT *********************** -->
        <div class="d-flex flex-column" v-if="otherValue === 'product'">
          {{ option[optionsLabel] }}
        </div>

        <!-- *********************** DEFAULT *********************** -->
        <div class="d-flex flex-column" v-if="!otherValue">
          {{ option[optionsLabel] }}
        </div>
      </template>


      <template #selected-option="option" v-if="otherValue !== ''">
         <!-- *********************** SAVED PROFORMS *********************** -->
        <template v-if="otherValue === 'savedProforms'">
          {{ option.values[0].translationStorage[proformUserConfig.lang].name }} &nbsp;
          {{ option.handmade === 1 ? 'handmade' : '' }} &nbsp;
          price {{ option.item_price }}
        </template>

        <!-- *********************** PROFORM HS CODE *********************** -->
        <template v-if="otherValue === 'proformHSCode'">
          {{option.proform_value.translationStorage.ua.name}}
        </template>

        <!-- *********************** PROFORM HS CODE *********************** -->
        <template v-if="otherValue === 'sendFrom'">
          {{ option.first_name }}
          {{ option.last_name }},
          {{ option.company_name }}
        </template>

        <!-- *********************** PROFORM DATA *********************** -->
        <template v-if="otherValue === 'proformData'">
          {{option.translationStorage[proformUserConfig.lang].name}}
        </template>

        <!-- *********************** PROFORM DATA PRODUCT *********************** -->
        <template v-if="otherValue === 'proformDataProduct' && option && option.value">
          {{option.value.translationStorage[proformUserConfig.lang].name}}
        </template>

        <!-- *********************** NOVA POSHTA DEPARTMENT *********************** -->
        <template v-if="otherValue === 'novaPoshtaDepartment'">
          {{option.translationStorage.ua.name}}
        </template>

        <!-- *********************** PACKAGING SELECT *********************** -->
        <template v-if="otherValue === 'packagingSelect'">
          <div>
            {{ option.package_name }}
            <span>
              ({{ option.height }}*{{ option['length'] }}*{{ option.weight }}*{{ option.width }})
            </span>
          </div>
        </template>

        <!-- *********************** SELECT ICONS *********************** -->
        <template v-if="otherValue === 'selectIcons'">
          <div class="d-flex">
            <span  v-if="option[optionIcoVariable] || option.src" class="mr-1">
              <img :src="optionIcoVariable ? option[optionIcoVariable] : option.src" alt="ico" :height="heightIco"/>
            </span>
            {{ optionIcoName ? option[optionIcoName] : option.title }}
          </div>
        </template>

        <!-- *********************** GROUPS *********************** -->
        <template v-if="otherValue === 'groups'">
          <div class="d-flex">
            {{ option.name }}
          </div>
        </template>

        <!-- *********************** ACCOUNTS *********************** -->
        <template v-if="otherValue === 'accounts'">
          <img class="mr-2" v-if="option.bank.logo_base64" :src="option.bank.logo_base64" alt="ico" height="18">
          <img v-else class="mr-2" width="15" src="/img/dashboard-group/balance-icon.svg" alt="img">

          <span class="word-break">
            <template v-if="isIndividualBank(option)">
              <template v-if="
                option.bank_id !== ACCOUNT_BANK_LIST.ACCOUNT_BANK_WISE.id &&
                option.bank_id !== ACCOUNT_BANK_LIST.ACCOUNT_BANK_PAYONEER.id &&
                option.bank_id !== ACCOUNT_BANK_LIST.ACCOUNT_BANK_SWIFT.id &&
                option.bank_id !== ACCOUNT_BANK_LIST.ACCOUNT_BANK_KIT_GROUP.id &&
                option.bank_id !== ACCOUNT_BANK_LIST.ACCOUNT_BANK_RAMP.id &&
                option.bank_id !== ACCOUNT_BANK_LIST.ACCOUNT_BANK_CRYPTO.id">
                {{option.bank.currentTranslate.name}}<template v-if="option.card_number">
                , {{option.card_number}}</template><template v-if="option.first_name">
                , {{option.first_name}}</template><template v-if="option.last_name">
                , {{option.last_name}}</template><template v-if="option.email">
                , {{option.email}}</template><template v-if="option.phone">
                , {{option.phone}}</template>
              </template>
              <template v-else-if="
                option.bank_id === ACCOUNT_BANK_LIST.ACCOUNT_BANK_RAMP.id">
                {{option.bank.currentTranslate.name}}<template v-if="option.card_number">,
                {{option.card_number}}</template><template v-if="option.middle_name">,
                {{option.middle_name}}</template>
              </template>
              <template v-else>
                {{option.bank.currentTranslate.name}}<template v-if="option.full_name">, {{option.full_name}}</template>

                <template v-if="option.phone && option.bank_id === ACCOUNT_BANK_LIST.ACCOUNT_BANK_KIT_GROUP.id">
                  , {{option.phone}}, {{option.city}}</template>
                <template v-if="option.iban && option.bank_id === ACCOUNT_BANK_LIST.ACCOUNT_BANK_SWIFT.id">
                  , {{option.iban}}</template>
                <template v-if="option.crypto_name && option.bank_id === ACCOUNT_BANK_LIST.ACCOUNT_BANK_CRYPTO.id">,
                  {{option.crypto_name}}</template>
                <template v-if="option.crypto_wallet && option.bank_id === ACCOUNT_BANK_LIST.ACCOUNT_BANK_CRYPTO.id">,
                  {{option.crypto_wallet}}</template>
              </template>
            </template>

            <template v-if="isBusinessBank(option)">
              {{option['full_name']}},
              {{option['iban']}}
            </template>
          </span>

        </template>

        <!-- *********************** CURRENT TRANSLATION *********************** -->
        <template v-if="otherValue === 'currentTranslation'">
          {{ option.currentTranslate.name }}
        </template>

        <!-- *********************** CALCULATOR CUSTOMIZATION *********************** -->
        <template v-if="otherValue === 'calculatorCustomization'">
          {{ option.name }}, ${{ option.price }}
        </template>

        <!-- *********************** TRANSLATION *********************** -->
        <template v-if="otherValue === 'translation'">
          {{ $t(`${option.translation}.localization_value.value`)}}
        </template>

        <!-- *********************** CONSOLIDATION UNION *********************** -->
        <div class="d-flex" v-if="otherValue === 'consolidationUnion'">
          {{option.delivery_service.name}}:
          {{option.tracking_number}}
        </div>

        <!-- *********************** ADMIN *********************** -->
        <div class="d-flex" v-if="otherValue === 'adminItem'">
          {{ option.user_personal_contact.user_full_name }}
        </div>

        <!-- *********************** PRODUCT *********************** -->
        <template v-if="otherValue === 'product'">
          {{option[optionsLabel]}}
        </template>

        <!-- *********************** DEFAULT *********************** -->
        <template v-if="!otherValue">
          {{option[optionsLabel]}}
        </template>
      </template>

      <!--<template #search="{ attributes, events }">-->
        <!--{{attributes}}-->
        <!--<input-->
                <!--class="vs__search"-->
                <!--v-bind="attributes"-->
                <!--v-on="events"-->
        <!--&gt;-->
      <!--</template>-->


      <template slot="no-options" @click="$refs.select.open = false">
        <div v-if="defaultNoOptions">
          {{$t(`${defaultNoOptions}.localization_value.value`)}}
        </div>
        <div v-else>
          {{$t('common_SorryMatchingOptions.localization_value.value')}}
        </div>
      </template>

    </v-select>

    <span class="default-select__error error-field" v-if="error && errorTxt">{{errorTxt}}</span>
    <span class="default-select__caption" v-if="caption">{{caption}}</span>
  </div>
</template>

<script>
  import vSelect from 'vue-select'
  import { createPopper } from '@popperjs/core';
  import {ACCOUNT_BANK_LIST, ACCOUNT_TYPE} from "../../../../staticData/staticVariables";
  import {accountsHelperMixin} from "../../../../mixins/account/accountMixin";

  export default {
    name: "DefaultSelect",
    components: {
      vSelect
    },

    mixins: [accountsHelperMixin],

    props: [
      'options',
      'selected',
      'error',
      'errorTxt',
      'type',
      'label',
      'caption',
      'optionsLabel',
      'selectIcons',
      'heightIco',
      'vLabel',
      'multiple',
      'disabled',
      'optionIcoName',
      'otherValue',
      'defaultNoOptions',
      'clearable',
      'searchable',
      'optionIcoVariable',
    ],

    data(){
      return{
        vSelectModel: '',
        placement: 'bottom',

        ACCOUNT_TYPE: ACCOUNT_TYPE,
        ACCOUNT_BANK_LIST: ACCOUNT_BANK_LIST,

        myFilter: (option, label, search) => {
          if(this.otherValue === 'proformDataProduct'){
            let temp = search.toLowerCase();
            return option.value.translationStorage.ua.name.toLowerCase().indexOf(temp) > -1
          }
          if(this.otherValue === 'accounts'){
            let temp = search.toLowerCase();
            return (option.bank.currentTranslate?.name && option.bank.currentTranslate?.name.toLowerCase().indexOf(temp) > -1) ||
              (option.phone && option.phone.toLowerCase().indexOf(temp) > -1) ||
              (option.card_number && option.card_number.toLowerCase().indexOf(temp) > -1) ||
              (option.full_name && option.full_name.toLowerCase().indexOf(temp) > -1) ||
              (option.first_name && option.first_name.toLowerCase().indexOf(temp) > -1) ||
              (option.last_name && option.last_name.toLowerCase().indexOf(temp) > -1) ||
              (option.middle_name && option.middle_name.toLowerCase().indexOf(temp) > -1) ||
              (option.email && option.email.toLowerCase().indexOf(temp) > -1)
          }

          if(this.otherValue === 'product'){
            let temp = search.toLowerCase();
            return (option.name.toLowerCase().indexOf(temp) > -1) ||
                (option.sku_code && option.sku_code.toLowerCase().indexOf(temp) > -1) ||
                (option.upc_code && option.upc_code.toLowerCase().indexOf(temp) > -1)
          }
          if(this.otherValue === 'currentTranslation') {
            let temp = search.toLowerCase();
            return option.currentTranslate.name.toLowerCase().indexOf(temp) > -1
          }

          if(this.otherValue === 'proformHSCode') {
            let temp = search.toLowerCase();
            return option?.proform_value?.translationStorage?.ua?.name.toLowerCase().indexOf(temp) > -1
          }

          if(this.otherValue === 'sendFrom') {
            let temp = search.toLowerCase();
            return option?.first_name.toLowerCase().indexOf(temp) > -1 ||
             option?.last_name.toLowerCase().indexOf(temp) > -1 ||
             option?.company_name.toLowerCase().indexOf(temp) > -1
          }

          if(this.otherValue === 'consolidationUnion') {
            let temp = search.toLowerCase();
            return option?.tracking_number ? option?.tracking_number.toLowerCase().indexOf(temp) > -1 : false
          }

          if(this.optionsLabel === 'combo_name') {
            let temp = search.toLowerCase();
            return (search.indexOf('cell-') > -1 && Number(search.split('cell-')[1]) === option?.id)
                || option?.combo_name.toLowerCase().indexOf(temp) > -1
          }

          if(this.otherValue === 'consolidationUnion') {
            let temp = search.toLowerCase();
            return option?.tracking_number ? option?.tracking_number.toLowerCase().indexOf(temp) > -1 : false
          }

          if(this.otherValue === 'novaPoshtaDepartment') {
            let temp = search.toLowerCase();
            return option?.translationStorage?.ua?.name ? option?.translationStorage?.ua?.name.toLowerCase().indexOf(temp) > -1 : false
          }

          return (label || '').toLowerCase().indexOf(search.toLowerCase()) > -1
        }
      }
    },

    mounted() {
      this.vSelectModel = this.selected;
    },

    watch: {
      selected: function(newVal) {
        this.vSelectModel = newVal;
      }
    },

    methods: {



      asd(e) {
        console.log(e);
      },

      onChange(){
        let value = this.vSelectModel !== null ? this.vSelectModel : ''
        this.$emit('change', value);
      },

      withPopper (dropdownList, component, {width}) {
        /**
         * We need to explicitly define the dropdown width since
         * it is usually inherited from the parent with CSS.
         */
        dropdownList.style.width = width;

        /**
         * Here we position the dropdownList relative to the $refs.toggle Element.
         *
         * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
         * the dropdownList overlap by 1 pixel.
         *
         * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
         * wrapper so that we can set some styles for when the dropdown is placed
         * above.
         */
        const popper = createPopper(component.$refs.toggle, dropdownList, {
          placement: this.placement,
          modifiers: [
            {
              name: 'offset', options: {
                offset: [0, -1]
              }
            },
            {
              name: 'toggleClass',
              enabled: true,
              phase: 'write',
              fn ({state}) {
                component.$el.classList.toggle('drop-up', state.placement === 'top')
              },
            }]
        });

        /**
         * To prevent memory leaks Popper needs to be destroyed.
         * If you return function, it will be called just before dropdown is removed from DOM.
         */
        return () => popper.destroy();
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../scss/colors";
  @import "../../../../scss/mixins/mixins";
  @import "vue-select/src/scss/vue-select.scss";

  .default-select{
    position: relative;

    &.white .vs__dropdown-toggle{
      background: white;
    }

    &__option-ico{
      margin-right: 10px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }

    &--active {
      .v-select__label {
        top: -5px;
        //top: auto;
        //bottom: 36px;
        white-space: nowrap;
        background: white;
        background: linear-gradient(0deg, #F8F4EE 0%, #FFFFFF 105.56%);
        padding: 0 2px;
        font-size: 10px;
        line-height: 12px;
      }

      .v-select .vs__dropdown-toggle{
        border-color: $borderBrown;

      }
    }


    &.ui-no-valid .v-select .vs__dropdown-toggle{
      border: 2px solid $orange;
    }


    &__error{
      position: absolute;
      bottom: -18px;
      right: 0;
      font-size: 11px;
      line-height: 13px;
      color: $orange;
    }

    &__caption{
      width: 100%;
      text-align: right;
      font-size: 11px;
      line-height: 13px;
      color: $borderBrown;
      display: flex;
      justify-content: flex-end;
      margin-top: 15px;
      margin-bottom: 0;
    }

  }




    .v-select__label{
      position: absolute;
      left: 16px;
      top: 13px;
      font-size: 14px;
      line-height: 16px;
      color: #BBAD9C;
      transition: 0.15s all;
    }

    .vs--open .v-select__label{
      top: -5px;
      background: white;
      background: linear-gradient(0deg, #F8F4EE 0%, #FFFFFF 105.56%);
      padding: 0 2px;
      font-size: 10px;
      line-height: 12px;
    }

    .vs--open .vs__dropdown-toggle{

      border-color: $accent!important;
    }

    .vs__dropdown-toggle{
      padding-left: 16px;
      min-height: 42px;
      background: #F8F4EE;
      border: 1px solid #BBAD9C;
      box-sizing: border-box;
      border-radius: 5px;
    }

    .vs--open .vs__dropdown-toggle{
      border-color: #CD9E64;
    }

    .vs__selected{
      padding: 0;
      margin: 0;
      top: 8px;


    }

    .vs__search, .vs__search:focus{
      padding: 0;
    }

    .vs__selected-options{
      padding: 0;
      top: 2px;
      /*display: block;*/
      /*overflow: hidden;*/
    }



    .vs__dropdown-menu{
      /*top: 0;*/
      border-radius: 5px 5px 0 0;
      border: 1px solid $mainBg;
      box-shadow: 0px 4px 7px $mainBg;
      padding-top: 0;
      padding-bottom: 0;

      /* width */
      &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
        border: 1px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: transparent;
        margin-right: 8px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $borderBrown;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: $borderBrown;
        opacity: .5;
      }

      .vs__no-options{
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include for-550 {
          font-size: 13px;
        }
      }



      .vs__actions svg path{
        fill: #8F7A61;
      }

      .vs__dropdown-option{
        min-height: 42px;
        display: flex;
        align-items: center;
        color: $black;
        white-space: normal;
        border-bottom: 1px solid $mainBg;

        &:last-child{
          border-bottom: 0;
        }

        &--highlight{
          background: $borderBrownOpacity02;
          color: $black;
        }
      }

    }

    .v-select.drop-up.vs--open .vs__dropdown-toggle {
      border-radius: 0 0 4px 4px;
      border-top-color: transparent;
      border-bottom-color: rgba(60, 60, 60, 0.26);
    }

    [data-popper-placement='top'] {
      border-radius: 4px 4px 0 0;
      border-top-style: solid;
      border-bottom-style: none;
      box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15)
    }


</style>
